import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import Authentication from "services/Authentication";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.css";
import mafCodeValidationSchema from "./schemas/mafCodeValidationSchema";
import loginSchema from "./schemas/loginSchema";

let auth = new Authentication();

const getQueryParams = (query = null) =>
  (query || window.location.search.replace("?", ""))
    .split("&")
    .map((e) => e.split("=").map(decodeURIComponent))
    // eslint-disable-next-line no-sequences
    .reduce((r, [k, v]) => ((r[k] = v), r), {});

const Login = () => {
  let history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isAdamosNativeLoginInProgress, setAdamosNativeLoginInProgress] =
    useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const [challengeData, setChallengeData] = useState(null);

  const [isTotpSetupRequired, setIsTotpSetupRequired] = useState(false);
  const [totpData, setTotpData] = useState(null);
  const [totpCode, setTotpCode] = useState("");
  const [totpSetUpEmail, setTotpSetUpEmail] = useState("");

  const captchaRef = useRef(null);
  const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;
  const setIsVerified = false;

  const handleLogin = async (formValues) => {
    setLoading(true);
    try {
      const captcha = await captchaRef.current.getValue();
      const { email, password } = formValues;
      if (email && password) {
        const response = await auth.login({ email, password, captcha });
        if (response.challengeName === "MFA_SETUP") {
          setTotpSetUpEmail(email);
          setIsTotpSetupRequired(true);
          setChallengeData({
            session: response.session,
          });
        } else if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
          setIsMfaRequired(true);
          setChallengeData({
            session: response.session,
            email,
          });
        } else {
          history.push("/");
        }
      }
    } catch (err) {
      setInvalidCredentialsError("Incorrect Email or Password");
    } finally {
      setLoading(false);
    }
  };

  const startTotpSetup = async () => {
    setLoading(true);
    try {
      const response = await auth.associateSoftwareToken({
        session: challengeData.session,
        email: totpSetUpEmail,
      });
      setTotpData({
        qrCodeUrl: response.qrCodeUrl,
        secretCode: response.secretCode,
        session: response.session,
      });
    } catch (error) {
      setInvalidCredentialsError("Failed to start TOTP setup");
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = async () => {
    setLoading(true);
    try {
      await auth.respondToMfaChallenge({
        email: challengeData.email,
        mfaCode,
        session: challengeData.session,
        challengeName: "SOFTWARE_TOKEN_MFA",
      });
      history.push("/");
    } catch (err) {
      setInvalidCredentialsError("Invalid MFA Code");
    } finally {
      setLoading(false);
    }
  };

  const handleTotpVerify = async () => {
    setLoading(true);
    try {
      await auth.verifySoftwareToken({
        userCode: totpCode,
        session: totpData.session,
      });
      history.push("/");
    } catch (error) {
      setInvalidCredentialsError("Invalid verification code");
    } finally {
      setLoading(false);
    }
  };

  const handleAdamosLogin = (event) => {
    event?.preventDefault();
    const adamosAuthURL = `${process.env.REACT_APP_ADAMOS_OAUTH_URL}/authorize?response_type=code&client_id=${process.env.REACT_APP_ADAMOS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ADAMOS_REDIRECT_URL}&scope=openid%20profile%20email`;
    window.location.replace(adamosAuthURL);
  };

  useEffect(() => {
    const { iss } = getQueryParams();
    if (iss && iss.includes(process.env.REACT_APP_ADAMOS_OAUTH_URL)) {
      setAdamosNativeLoginInProgress(true);
      handleAdamosLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleShowPassword = (event) => {
    event.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const handleVerify = () => {
    setIsVerified(true);
  };
  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img
          className="login-logo m-2 ml-4"
          src="assets/images/ll4.png"
          alt="logo"
        />
      </a>
      <div className="account-pages m-0" style={{ backgroundColor: "white" }}>
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <img
              className="login-image"
              src="assets/images/SignOnPage.png"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <div>
              <div>
                <div className="p-1">
                  <h1 className="text-center">Chatter-free Milling</h1>
                  <h2 className="text-center mt-4">Welcome Back</h2>
                </div>
                {isTotpSetupRequired ? (
                  <div className="mt-4 d-flex align-items-center justify-content-center flex-column">
                    <h3>Set Up MFA</h3>
                    {totpData ? (
                      <div>
                        <p>Scan the QR code with Google Authenticator:</p>
                        <img
                          src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                            totpData.qrCodeUrl
                          )}`}
                          alt="QR Code"
                        />
                        <form className="mt-3" onSubmit={handleTotpVerify}>
                          <input
                            type="text"
                            placeholder="Enter code from app"
                            value={totpCode}
                            onChange={(e) => setTotpCode(e.target.value)}
                            required
                          />
                          <button
                            className="btn btn-primary btn-sm ml-2 mfa-button"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Verifying..." : "Verify"}
                          </button>
                        </form>
                        {invalidCredentialsError && (
                          <p className="text-danger">
                            {invalidCredentialsError}
                          </p>
                        )}
                      </div>
                    ) : (
                      <div>
                        <button
                          className="btn btn-primary btn-lg mfa-button"
                          onClick={startTotpSetup}
                          disabled={loading}
                          style={{ cursor: "pointer" }}
                        >
                          {loading ? "Starting..." : "Start TOTP Setup"}
                        </button>
                      </div>
                    )}
                  </div>
                ) : isMfaRequired ? (
                  <Form
                    className="form-horizontal mt-5"
                    initialValues={{}}
                    validationSchema={mafCodeValidationSchema}
                    onSubmit={handleMfaSubmit}
                  >
                    <div className="form-group row">
                      <h3>Enter MFA Code</h3>
                      <div className="col-12 mt-4 d-flex justify-content-center align-items-center flex-column">
                        {" "}
                        <InputNonOptimized
                          className="form-control w-50"
                          type="text"
                          id="mfaCode"
                          name="mfaCode"
                          value={mfaCode}
                          onChange={(e) => setMfaCode(e.target.value)}
                        />
                        <ErrorMessage name="mfaCode" />
                        {invalidCredentialsError && (
                          <p className="text-danger">
                            {invalidCredentialsError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-center">
                      <SubmitButton
                        className="btn btn-primary btn-lg mfa-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <i className="fas fa-circle-notch fa-spin"></i>
                        ) : (
                          "Submit"
                        )}
                      </SubmitButton>
                    </div>
                  </Form>
                ) : (
                  <Form
                    className="form-horizontal"
                    initialValues={{}}
                    validationSchema={loginSchema}
                    onSubmit={handleLogin}
                  >
                    <div className="form-group row">
                      <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                        {" "}
                        <InputNonOptimized
                          name="email"
                          className="form-control login-input"
                          type="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <ErrorMessage name="email" />
                    </div>
                    <div className="form-group row">
                      <div className=" col-12 mt-3">
                        <div className="d-flex justify-content-center align-items-center">
                          <InputNonOptimized
                            name="password"
                            className="form-control login-password-input"
                            type={passwordShown ? "text" : "password"}
                            placeholder="Password"
                          />
                          <button className="show-password-button">
                            <i
                              onClick={(event) => handleShowPassword(event)}
                              className={
                                passwordShown
                                  ? "ion ion-md-eye"
                                  : "ion ion-md-eye-off"
                              }
                            />
                          </button>
                        </div>
                      </div>
                      <ErrorMessage name="password" />
                      {invalidCredentialsError && (
                        <p className="text-danger mb-0">
                          {invalidCredentialsError}
                        </p>
                      )}
                    </div>
                    <div className="form-group row m-t-18">
                      <Link
                        to="/recovery-request"
                        className="text-muted forget-password-button"
                        style={{ marginLeft: "12rem" }}
                      >
                        Forgot Password?{" "}
                      </Link>
                      <div className="col-12 mt-3 d-flex justify-content-center">
                        <SubmitButton
                          className="btn btn-primary btn-lg login-button"
                          type="submit"
                          onClick={handleLogin}
                        >
                          {loading ? (
                            <i className="fas fa-circle-notch fa-spin"></i>
                          ) : (
                            "Sign In"
                          )}
                        </SubmitButton>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <ReCAPTCHA
                          sitekey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                          onChange={handleVerify}
                          ref={captchaRef}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
